import axios from "axios";
import api from "@/api";
// import jwt_decode from "jwt-decode";

function getItem(itemName, defaultValue) {
  let localItem = localStorage.getItem(itemName);
  if (localItem != undefined) {
    return localItem;
  } else {
    return defaultValue;
  }
}

export default {
  state: () => ({
    username: "admin",
    token: getItem("admin-token"),
    userID: -1,
    profilePicture:
      "https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png",
  }),
  mutations: {
    login(state, { token }) {
      state.token = token;
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      // state.username = username;
      // state.userID = userID;
    },
    logout(state) {
      state.token = undefined;
      // state.username = undefined;
      // state.userID = undefined;

      localStorage.removeItem("admin-token");
      localStorage.removeItem("user-type");
      localStorage.removeItem("username");

      // localStorage.removeItem('username')
      // localStorage.removeItem('user-id')

      delete axios.defaults.headers.common["Authorization"];
    },
    resetPassword(state, { data }) {
      state.new_password = data.password;
    },
  },
  actions: {
    login({ commit }, userData) {
      axios.defaults.baseURL = api.baseURL;
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.loginURL,
            data: userData,
            method: "POST",
          })
          .then((loginData) => {
            console.log(loginData);
            const token = loginData.token.access;
            const type = loginData.user.user_type;
            const username = loginData.user.username;

            localStorage.setItem("admin-token", token);
            localStorage.setItem("user-type", type);
            localStorage.setItem("username", username);

            console.log("Login success");
            commit("login", { token });
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
    logout({ getters }) {
      axios.defaults.baseURL = api.baseURL;
      const token = localStorage.getItem("admin-token");
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      return new Promise((resolve, reject) => {
        if (!getters.isAuthenticated) {
          resolve();
        } else {
          api
            .actionHandler({
              url: api.logoutURL,
              method: "POST",
            })
            .then(() => {
              resolve();
            })
            .catch((errMsg) => reject(errMsg));
        }
      });
    },
    resetPassword({ commit }, userData) {
      axios.defaults.baseURL = api.baseURL;
      const token = localStorage.getItem("admin-token");
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.resetURL,
            data: userData,
            method: "POST",
          })
          .then(() => {
            commit("reset");
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
  },
  getters: {
    isAuthenticated(state) {
      return !!state.token;
    },
  },
};
