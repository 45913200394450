import api from "@/api";

export default {
  state: () => ({
    distributors: [],
    distributorDetails: {},
    isLoaded: false,
    distributorCount: null,
  }),
  mutations: {
    updateDistributorList(state, { distributorList }) {
      state.distributors = [];
      distributorList.results.forEach((distributor) => state.distributors.push(distributor));
      state.isLoaded = true;
    },
    updateDistributorDetails(state, { distributorDetails }) {
      state.distributorDetails = distributorDetails;
    },
    deleteDistributor(state, distributorIndex) {
      state.distributors.splice(distributorIndex, 1);
    },
  },
  actions: {
    fetchDistributorList({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.distributorListURL,
            method: "GET",
          })
          .then((distributorList) => {
            commit("updateDistributorList", { distributorList });
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
    fetchDistributorDetails({ commit }, { distributorID }) {
      console.log(distributorID);
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.distributorDetailsURL.replace("{id}", distributorID),
            method: "GET",
          })
          .then((distributorDetails) => {
            commit("updateDistributorDetails", { distributorDetails });
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
    setDistributorDetails({ commit }, { payload, distributorID }) {
      let edit = distributorID != undefined;
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: edit
              ? api.distributorDetailsURL.replace("{id}", distributorID)
              : api.distributorListURL,
            data: payload,
            method: edit ? "PATCH" : "POST",
          })
          .then(() => {
            commit("distributorUpdated");
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
    deleteDistributor({ commit }, { index, miscData: { itemID } }) {
      let distributorID = itemID;
      let distributorIndex = index;

      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.distributorDetailsURL.replace("{id}", distributorID),
            method: "DELETE",
          })
          .then(() => {
            commit("deleteDistributor", distributorIndex);
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
  },
};
