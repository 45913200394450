import Vue from "vue";
import Router from "vue-router";
import store from "./store/store.js";

Vue.use(Router);

// let userType = localStorage.getItem("user-type");

var availablePaths = [
  {
    path: "/",
    redirect: "/login",
    component: () => import("./views/Authentification/Login"),
    children: [
      {
        path: "/login",
        name: "login",
        productionAvailable: true,
      },
    ],
    productionAvailable: true,
  },
  {
    path: "/",
    redirect:
      localStorage.getItem("user-type") === "admin"
        ? "/products"
        : "/staff-management",
    component: () => import("./layout/DashboardLayout"),
    meta: { requiresAuth: true } /* LOGIN LOGOUT IMPL MARKER */,
    productionAvailable: true,
    children: [
      {
        path: "products",
        name: "products",
        component: () => import("./views/Products/Index"),
      },
      {
        path: "distributors",
        name: "distributors",
        component: () => import("./views/Distributors/Index"),
      },
      {
        path: "staff-management",
        name: "staff-management",
        component: () => import("./views/Staff/Index"),
      },
      {
        path: "user-management",
        name: "user-management",
        component: () => import("./views/Users/Index"),
      },
      {
        path: "messages",
        name: "messages",
        component: () => import("./views/Messages/Index"),
      },
      {
        path: "routes",
        name: "routes",
        component: () => import("./views/Routes/Index"),
      },
      {
        path: "shops",
        name: "shops",
        component: () => import("./views/Shops/Index"),
      },
      {
        path: "asm",
        name: "ASM",
        component: () => import("./views/ASM/Index"),
      },
      {
        path: "staff-report",
        name: "StaffReport",
        component: () => import("./views/Reports/StaffReport/Index"),
      },
      {
        path: "location-report",
        name: "LocationReport",
        component: () => import("./views/Reports/LocationReport/Index"),
      },
      {
        path: "staff-activity-report",
        name: "StaffActivityReport",
        component: () => import("./views/Reports/StaffActivityReport/Index"),
      },
      {
        path: "distributer-report",
        name: "DistributerReport",
        component: () => import("./views/Reports/DistributerReport/Index"),
      },
      {
        path: "shop-report",
        name: "ShopReport",
        component: () => import("./views/Reports/ShopReport/Index"),
      },
      {
        path: "my-plan-report",
        name: "MyPlanReport",
        component: () => import("./views/Reports/MyPlanReport/Index"),
      },
      {
        path: "asm-report",
        name: "AsmReport",
        component: () => import("./views/Reports/AsmReport/Index"),
      },
      {
        path: "dsm-report",
        name: "DsmReport",
        component: () => import("./views/Reports/DSMReport/Index"),
      },
      {
        path: "profile",
        name: "Profile",
        component: () => import("./views/Profile/Profile"),
      },
    ],
  },
  {
    path: "*",
    name: "not found",
    component: () => import("./views/Utils/NotFound"),
    productionAvailable: false,
  },
];

const router = new Router({
  mode: "history",
  base: "/admin/",
  linkExactActiveClass: "active",
  routes:
    process.env.NODE_ENV === "production"
      ? availablePaths.filter((route) => route.productionAvailable)
      : availablePaths,
  scrollBehavior() {
    return { x: 0, y: 0, behavior: "smooth" };
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // This route requires auth, check if authenticated
    // If not, redirect to login page
    if (!store.getters.isAuthenticated) {
      next({
        path: "/login",
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      next();
    }
  } else {
    if (to.name == "login" && store.getters.isAuthenticated) {
      // Redirect to the Dashboard as the user attempted to view the
      // login page while being authenticated already
      next({
        path:
          localStorage.getItem("user-type") === "admin"
            ? "/products"
            : "/staff-management",
      });
    } else {
      next();
    }
  }
});

export default router;
