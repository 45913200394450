<template>
  <stats-card
    :title="headerCardData.title"
    :type="headerCardData.iconGradient"
    :sub-title="headerCardData.content"
    :icon="headerCardData.icon"
    class="shadow bg-white rounded"
  >
    <template slot="footer">
      <span v-if="headerCardData.changePositive" class="text-success mr-2"
        ><i class="fa fa-arrow-up"></i> {{ headerCardData.changePercent }}</span
      >
      <span v-else class="text-danger mr-2"
        ><i class="fa fa-arrow-down"></i>
        {{ headerCardData.changePercent }}</span
      >
      <span class="text-nowrap">than previous</span>
    </template>
  </stats-card>
</template>
<script>
import StatsCard from "./StatsCard.vue";

export default {
  name: "header-card",
  components: {
    StatsCard,
  },
  props: {
    headerCardData: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style></style>
