import Vue from "vue";
import Vuex from "vuex";
// import notificationStore from "./NotificationStore.js";
import accountData from "./AccountStore.js";
import profileStore from "./ProfileStore.js";
import productStore from "./productStore/productStore";
import distributorStore from "./distributorStore/distributorStore";
import asmStore from "./asmStore/asmStore";
import routeStore from "./routeStore/routeStore";
import staffStore from "./staffStore/staffStore";
import reportStore from "./reportStore/reportStore";
import notificationStore from "./notificationStore/notificationStore"
import usersStore from "./usersStore/usersStore"

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    accountData,
    productStore,
    distributorStore,
    asmStore,
    routeStore,
    staffStore,
    reportStore,
    usersStore,
	notificationStore
  },
  state: {
    // notifications: notificationStore,
    profileData: profileStore,
    displayLoader: 0,
  },
  mutations: {
    displayLoader(state, displayLoader) {
      // Loader will be displayed if at least one action is running
      state.displayLoader += displayLoader ? +1 : -1;
      if (state.displayLoader < 0) {
        state.displayLoader = 0;
      }
    },
    toggleLoader(state) {
      // Not recommended to use
      state.displayLoader = state.displayLoader == 0 ? 1 : 0;
    },
  },
  actions: {},
  strict: process.env.NODE_ENV !== "production",
});
store.subscribeAction({
  before: () => {
    store.commit("displayLoader", true);
  },
  after: () => {
    store.commit("displayLoader", false);
  },
  error: () => {
    store.commit("displayLoader", false);
  },
});

// Display the loader when actions are being run

export default store;
