import api from "@/api";

export default {
  state: () => ({
    routes: [],
    routeDetails: {},
    isLoaded: false,
    routeCount: null,
  }),
  mutations: {
    updateRouteList(state, { routeList }) {
      state.routes = [];
      routeList.results.forEach((route) => state.routes.push(route));
      state.isLoaded = true;
    },
    updateRouteDetails(state, { routeDetails }) {
      state.routeDetails = routeDetails;
    },
    deleteRoute(state, routeIndex) {
      state.routes.splice(routeIndex, 1);
    },
  },
  actions: {
    fetchRouteList({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.routeListURL,
            method: "GET",
          })
          .then((routeList) => {
            commit("updateRouteList", { routeList });
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
    fetchRouteDetails({ commit }, { routeID }) {
      console.log(routeID);
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.routeDetailsURL.replace("{id}", routeID),
            method: "GET",
          })
          .then((routeDetails) => {
            commit("updateRouteDetails", { routeDetails });
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
    setRouteDetails({ commit }, { payload, routeID }) {
      let edit = routeID != undefined;
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: edit
              ? api.routeDetailsURL.replace("{id}", routeID)
              : api.routeListURL,
            data: payload,
            method: edit ? "PATCH" : "POST",
          })
          .then(() => {
            commit("routeUpdated");
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
    deleteRoute({ commit }, { index, miscData: { itemID } }) {
      let routeID = itemID;
      let routeIndex = index;

      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.routeDetailsURL.replace("{id}", routeID),
            method: "DELETE",
          })
          .then(() => {
            commit("deleteRoute", routeIndex);
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
  },
};
