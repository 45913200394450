<template>
	<modal :show="isValidDeleteIndex" @close="signal('actionCancelled')">
		<template v-slot:header>
			<slot name="header">Delete {{ item }}</slot>
		</template>
		<slot>
			Are you sure you want to delete this {{ item }}?
		</slot>
		<div class="mt-1" v-if="$slots.warning">
			<span class="text-warning font-weight-bold">
				<i class="fa fa-exclamation-triangle mr-1" />Warning: 
			</span>
			<slot name="warning"></slot>
		</div>
		<template v-slot:footer>
			<button class="btn btn-danger" @click.prevent="deleteItem">Delete</button>
		</template>
	</modal>
</template>

<script>
export default {
	name: 'delete-dialog',
	props: {
		item: {
			type: String, 
			default: "item",
			required: false,
		},
		data: {
			type: Array,
			required: true
		},
		miscData: {
			type: Object,
			required: false
		},
		actionString: {
			type: String,
		},
		value: {
			type: Number,
			default: -1,
			required: true,
		},
	},
	data() {
		return {
		}
	},
	methods: {
		deleteItem() {
			if (!this.actionString) {
				this.data.splice(this.value, 1);
				this.signal('actionComplete');
				return;
			}

			if (this.isValidDeleteIndex) {
				this.$emit('performingAction');

				var index = this.value;
				var id = this.getID();
				var miscData = this.miscData;

				this.$store.dispatch(this.actionString, { id, index, miscData })
				.then(() => {
					this.signal('actionComplete');
				}).catch(err => {
					this.$notify({
						title: "Failed to delete " + this.item,
						icon: 'fa fa-exclamation-triangle',
						type: 'danger',
						message: err.toString(),
					});
					this.$emit('actionFailed');
				});
			} else {
				this.$emit('actionFailed');
			}
		},
		getID() {
			var index = this.value;
			// Get the item ID from the data item itself
			return this.data[index].id;
		},
		signal(signal) {
			this.$emit(signal);
			this.$emit("input", -1);
		}
	},
	computed: {
		isValidDeleteIndex() {
			return this.value >= 0 && this.value < this.data.length;
		}
	}
}
</script>
<style scoped>
</style>
