<template>
    <card :type="type" header-classes="bg-transparent" class="card-shadow">
        <div slot="header" class="row align-items-center">
            <div class="col">
                <h6 :class="[ this.type ? 'text-light' : 'text-muted' ]" class="text-uppercase ls-1 mb-1">{{ chartData.title }}</h6>
                <h5 :class="[ this.type ? 'text-white' : '' ]" class="h3 mb-0">{{ chartData.subTitle }}</h5>
            </div>
        </div>

        <line-chart
                :height="350"
                ref="bigChart"
                :chart-data="chartData.chartData"
                :extra-options="chartData.extraOptions"
        >
        </line-chart>

    </card>
</template>
<script>
  import Card from './Card.vue';
  import LineChart from './Charts/LineChart';

  export default {
    name: 'line-chart-view',
    components: {
      Card,
      LineChart
    },
    props: {
      type: {
        type: String,
        description: "Line chart's card bg"
      },
      chartData: {
        type: Object,
        required: true
      }
    }
  };
</script>
<style>
.card-shadow {
    box-shadow: 0 5px 16px 1px #dedede;
}
</style>
