<template>
  <div class="d-flex flex-column bg-pimary">
    <div class="nav-tabs-navigation d-flex flex-column">
      <div class="nav-tabs-wrapper d-flex-flex-column">
        <slot name="nav"></slot>
      </div>
    </div>
    <div class="bg-primary">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "tabs-layout",
};
</script>
