import api from "@/api";
import axios from "axios";

export default {
  state: () => ({
    products: [],
    productDetails: {},
    isLoaded: false,
    productCount: null,
  }),
  mutations: {
    updateProductList(state, { productList }) {
      state.products = [];
      productList.results.forEach((product) => state.products.push(product));
      state.isLoaded = true;
    },
    updateProductDetails(state, { productDetails }) {
      state.productDetails = productDetails;
    },
    deleteProduct(state, productIndex) {
      state.products.splice(productIndex, 1);
    },
  },
  actions: {
    fetchProductList({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.productListURL,
            method: "GET",
          })
          .then((productList) => {
            commit("updateProductList", { productList });
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
    fetchProductDetails({ commit }, { productID }) {
      console.log(productID);
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.productDetailsURL.replace("{id}", productID),
            method: "GET",
          })
          .then((productDetails) => {
            commit("updateProductDetails", { productDetails });
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
    setProductDetails({ commit }, { payload, productID }) {
      let edit = productID != undefined;
      if (!edit) {
        return new Promise((resolve, reject) => {
          axios
            .post(api.productListURL, payload, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(() => {
              commit("productAdded");
              resolve();
            })
            .catch((errMsg) => reject(errMsg));
        });
      }
      else {
        return new Promise((resolve, reject) => {
          axios
            .patch(api.productDetailsURL.replace("{id}",productID), payload, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(() => {
              commit("productUpdated");
              resolve();
            })
            .catch((errMsg) => reject(errMsg));
        });
      }

      // return new Promise((resolve, reject) => {
      //   api
      //     .actionHandler({
      //       url: edit
      //         ? api.productDetailsURL.replace("{id}", productID)
      //         : api.productListURL,
      //       data: payload,
      //       method: edit ? "PATCH" : "POST",
      //     })
      //     .then(() => {
      //       commit("productUpdated");
      //       resolve();
      //     })
      //     .catch((errMsg) => reject(errMsg));
      // });
    },
    deleteProduct({ commit }, { index, miscData: { itemID } }) {
      let productID = itemID;
      let productIndex = index;

      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.productDetailsURL.replace("{id}", productID),
            method: "DELETE",
          })
          .then(() => {
            commit("deleteProduct", productIndex);
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
  },
};
