<template>
	<li class="nav-item w-100">
		<a v-if="$slots.default" class="d-flex flex-column align-items-start">
			<div
				class="nav-link w-100 d-flex cursor-pointer"
				@click.prevent="expanded = !expanded"
			>
				<i :class="link.icon"></i>
				<span class="nav-link-text text-white">{{ link.name }}</span>
				<div class="ml-auto">
					<i
						:class="[
							!expanded ? 'fa-caret-right' : 'fa-caret-down',
						]"
						class="fa text-muted"
					/>
					<!-- {{ !expanded ? '+' : '-' }} -->
				</div>
			</div>
			<collapse-transition>
				<ul class="nav w-100" v-if="expanded">
					<slot></slot>
				</ul>
			</collapse-transition>
		</a>

		<router-link
			v-else
			:to="link.path"
			@click.native="linkClick"
			class="nav-link"
			:target="link.target"
			:href="link.path"
		>
			<template>
				<i :class="link.icon"></i>
				<span class="nav-link-text text-white">{{ link.name }}</span>
			</template>
		</router-link>
	</li>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";

export default {
	name: "sidebar-item",
	components: {
		CollapseTransition,
	},
	props: {
		link: {
			type: Object,
			default: () => {
				return {
					name: "",
					path: "",
				};
			},
			description:
				"Sidebar link. Can contain name, path, icon and other attributes. See examples for more info",
		},
	},
	inject: {
		autoClose: {
			default: true,
		},
	},
	data() {
		return {
			collapsed: true,
			expanded: false,
		};
	},
	methods: {
		linkClick() {
			if (
				this.autoClose &&
				this.$sidebar &&
				this.$sidebar.showSidebar === true
			) {
				this.$sidebar.displaySidebar(false);
			}
		},
	},
};
</script>
<style scoped>
.cursor-pointer {
	cursor: pointer;
}
</style>
