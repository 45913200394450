import api from "@/api";

export default {
  state: () => ({
    staffs: [],
    shops: [],
    allShops: [],
    shopCount: null,
    allShopCount: null,
    staffDetails: {},
    shopDetails: {},
    isLoaded: false,
    staffCount: null,
  }),
  mutations: {
    updateStaffList(state, { staffList }) {
      state.staffs = [];
      staffList.results.forEach((staff) => state.staffs.push(staff));
      state.isLoaded = true;
    },
    updateStaffDetails(state, { staffDetails }) {
      state.staffDetails = staffDetails;
    },
    deleteStaff(state, staffIndex) {
      state.staffs.splice(staffIndex, 1);
    },
    updateShopList(state, { shopList }) {
      (state.shopCount = shopList.count),
        (state.shops = []),
        shopList.results.forEach((shop) => state.shops.push(shop));
    },
    updateAllShopList(state, { allShopList }) {
      (state.allShopCount = allShopList.count),
        (state.allShops = []),
        allShopList.results.forEach((shop) => state.allShops.push(shop));
    },
    updateShopDetails(state, { shopDetails }) {
      state.shopDetails = shopDetails;
    },
  },
  actions: {
    fetchStaffList({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.staffListURL,
            method: "GET",
          })
          .then((staffList) => {
            commit("updateStaffList", { staffList });
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
    fetchStaffDetails({ commit }, { staffID }) {
      console.log(staffID);
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.staffDetailsURL.replace("{id}", staffID),
            method: "GET",
          })
          .then((staffDetails) => {
            commit("updateStaffDetails", { staffDetails });
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
    setStaffDetails({ commit }, { payload, staffID }) {
      let edit = staffID != undefined;
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: edit
              ? api.staffDetailsURL.replace("{id}", staffID)
              : api.staffListURL,
            data: payload,
            method: edit ? "PATCH" : "POST",
          })
          .then(() => {
            commit("staffUpdated");
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
    deleteStaff({ commit }, { index, miscData: { itemID } }) {
      let staffID = itemID;
      let staffIndex = index;

      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.staffDetailsURL.replace("{id}", staffID),
            method: "DELETE",
          })
          .then(() => {
            commit("deleteStaff", staffIndex);
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
    fetchShopList({ commit }, { currentPage, pageSize, search }) {
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.shopListURL
              .replace("{offset}", currentPage)
              .replace("{limit}", pageSize)
              .replace("{search}", search),
            method: "GET",
          })
          .then((shopList) => {
            commit("updateShopList", { shopList });
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
    fetchAllShopList({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.shopListURL.replace(
              "?limit={limit}&offset={offset}&search={search}",
              "?limit=5000"
            ),
            method: "GET",
          })
          .then((allShopList) => {
            commit("updateAllShopList", { allShopList });
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
    fetchShopDetails({ commit }, { shopID }) {
      console.log(shopID);
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.shopDetailsURL.replace("{id}", shopID),
            method: "GET",
          })
          .then((shopDetails) => {
            commit("updateShopDetails", { shopDetails });
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
    setShopDetails({ commit }, { payload, shopID }) {
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.shopDetailsURL.replace("{id}", shopID),
            data: payload,
            method: "PATCH",
          })
          .then(() => {
            commit("shopUpdated");
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
  },
};
