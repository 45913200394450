import api from "@/api";

export default {
  state: () => ({
    notifications: [],
  }),
  mutations: {
    updateNotificationList(state, { notificationList }) {
      (state.notifications = []),
        notificationList.results.forEach((shop) =>
          state.notifications.push(shop)
        );
    },
  },
  actions: {
    fetchNotificationList({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.createNotificationURL,
            method: "GET",
          })
          .then((notificationList) => {
            commit("updateNotificationList", { notificationList });
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
    setNotification({ commit }, { payload }) {
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.createNotificationURL,
            data: payload,
            method: "POST",
          })
          .then(() => {
            commit("notificationCreated");
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
  },
};
