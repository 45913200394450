import api from "@/api";

export default {
  state: () => ({
    staffReport: [],
    staffRemarkReport: [],
    myplanReport: [],
    shopReport: [],
    distributorReport: [],
    asmReport: [],
    dsmReport: [],
  }),
  mutations: {
    updateStaffReport(state, { staffReport }) {
      state.staffReport = [];
      state.staffReport = staffReport;
    },
    updateStaffRemarkReport(state, { staffRemarkReport }) {
      state.staffRemarkReport = [];
      staffRemarkReport.results.forEach((asm) =>
        state.staffRemarkReport.push(asm)
      );
    },
    updateMyplanReport(state, { myplanReport }) {
      state.myplanReport = [];
      myplanReport.results.forEach((asm) => state.myplanReport.push(asm));
    },
    updateShopReport(state, { shopReport }) {
      state.shopReport = [];
      shopReport.forEach((asm) => state.shopReport.push(asm));
    },
    updateDistributorReport(state, { distributorReport }) {
      state.distributorReport = [];
      console.log("mutation", distributorReport);
      distributorReport.forEach((asm) => state.distributorReport.push(asm));
    },
    updateAsmReport(state, { asmReport }) {
      state.asmReport = [];
      asmReport.results.forEach((asm) => state.asmReport.push(asm));
    },
    updateDsmReport(state, { dsmReport }) {
      state.dsmReport = [];
      dsmReport.forEach((dsm) => state.dsmReport.push(dsm));
    },
  },
  actions: {
    fetchStaffReport({ commit }, { payload }) {
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.staffReportURL,
            data: payload,
            method: "POST",
          })
          .then((staffReport) => {
            console.log(staffReport);
            commit("updateStaffReport", { staffReport });
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
    fetchStaffRemarkReport({ commit }, { payload }) {
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.staffRemarkReportURL,
            data: payload,
            method: "POST",
          })
          .then((staffRemarkReport) => {
            commit("updateStaffRemarkReport", { staffRemarkReport });
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
    fetchMyPlanReport({ commit }, { payload }) {
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.myplanReportURL,
            data: payload,
            method: "POST",
          })
          .then((myplanReport) => {
            commit("updateMyplanReport", { myplanReport });
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
    fetchShopReport({ commit }, { payload }) {
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.shopReportURL,
            data: payload,
            method: "POST",
          })
          .then((shopReport) => {
            commit("updateShopReport", { shopReport });
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
    fetchDistributorReport({ commit }, { payload }) {
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.distributorReportURL,
            data: payload,
            method: "POST",
          })
          .then((distributorReport) => {
            commit("updateDistributorReport", { distributorReport });
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
    fetchASMReport({ commit }, { payload }) {
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.asmReportURL,
            method: "POST",
            data: payload,
          })
          .then((asmReport) => {
            console.log(asmReport);
            commit("updateAsmReport", { asmReport });
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
    fetchDSMReport({ commit }, { payload }) {
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.dsmReportURL,
            method: "POST",
            data: payload,
          })
          .then((dsmReport) => {
            console.log(dsmReport);
            commit("updateDsmReport", { dsmReport });
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
  },
};
