import api from "@/api";

export default {
  state: () => ({
    asmList: [],
    asmDetails: {},
    isLoaded: false,
    asmCount: null,
  }),
  mutations: {
    updateAsmList(state, { asmList }) {
      state.asmList = [];
      asmList.results.forEach((asm) => state.asmList.push(asm));
      state.isLoaded = true;
    },
    updateAsmDetails(state, { asmDetails }) {
      state.asmDetails = asmDetails;
    },
    deleteAsm(state, asmIndex) {
      state.asmList.splice(asmIndex, 1);
    },
  },
  actions: {
    fetchAsmList({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.asmListURL,
            method: "GET",
          })
          .then((asmList) => {
            commit("updateAsmList", { asmList });
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
    fetchAsmDetails({ commit }, { asmID }) {
      console.log(asmID);
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.asmDetailsURL.replace("{id}", asmID),
            method: "GET",
          })
          .then((asmDetails) => {
            commit("updateAsmDetails", { asmDetails });
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
    setAsmDetails({ commit }, { payload, asmID }) {
      let edit = asmID != undefined;
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: edit
              ? api.asmDetailsURL.replace("{id}", asmID)
              : api.asmListURL,
            data: payload,
            method: edit ? "PATCH" : "POST",
          })
          .then(() => {
            commit("asmUpdated");
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
    deleteAsm({ commit }, { index, miscData: { itemID } }) {
      let asmID = itemID;
      let asmIndex = index;

      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.asmDetailsURL.replace("{id}", asmID),
            method: "DELETE",
          })
          .then(() => {
            commit("deleteAsm", asmIndex);
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
  },
};
