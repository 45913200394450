import axios from "axios";
function actionHandler(payload) {
  return new Promise((resolve, reject) => {
    axios(payload)
      .then((response) => {
        let resp = response.data;
        if (response.status >= 200 && response.status < 300) {
          resolve(resp.data);
        } else {
          reject(resp.message);
        }
      })
      .catch((err) => {
        console.error(err);
        reject(getActionErrorMessage(err));
      });
  });
}

function getActionErrorMessage(err) {
  if (err.response?.data?.message) {
    return "Error: " + err.response.data.message;
  } else {
    return err;
  }
}

export default {
  baseURL: "https://senseapi.infonethra.com/api/v1/",

  /* auth URLs */
  loginURL: "auth/login/", // [POST]
  logoutURL: "auth/logout/", // [POST]
  resetURL: "reset_password/",

  productListURL: "admin_panel/products/?limit=50000",
  productDetailsURL: "admin_panel/product/{id}/",

  asmListURL: "admin_panel/asm/?limit=1000",
  asmDetailsURL: "admin_panel/asm/{id}/",

  routeListURL: "admin_panel/routes/?limit=50000",
  routeDetailsURL: "admin_panel/route/{id}/",

  distributorListURL: "admin_panel/distributors/?limit=50000",
  distributorDetailsURL: "admin_panel/distributor/{id}/",

  staffListURL: "admin_panel/staffs/?limit=50000",
  staffDetailsURL: "admin_panel/staff/{id}/",

  staffReportURL: "admin_panel/staff_report/?limit=5000",
  staffRemarkReportURL: "admin_panel/staff_remark_report/?limit=5000",
  myplanReportURL: "admin_panel/staff_plans_report/?limit=5000",
  shopReportURL: "admin_panel/shop_report/?limit=50",
  distributorReportURL: "admin_panel/distributor_report/?limit=5000",
  asmReportURL: "admin_panel/asm_report/?limit=5000",
  dsmReportURL: "admin_panel/dsm_report/?limit=5000",

  createNotificationURL: "admin_panel/notifications/",

  shopListURL:
    "admin_panel/shops/?limit={limit}&offset={offset}&search={search}",
  shopDetailsURL: "admin_panel/shop/{id}/",

  usersListURL: "admin_panel/asm_user/",
  userDetailsURL: "admin_panel/asm_user/{id}/",

  actionHandler,
};
