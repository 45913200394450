import api from "@/api";

export default {
  state: () => ({
    users: [],
    userDetails: {},
    isLoaded: false,
    userCount: null,
  }),
  mutations: {
    updateUsersList(state, { usersList }) {
      state.users = [];
      usersList.results.forEach((user) => state.users.push(user));
      state.isLoaded = true;
    },
    updateUserDetails(state, { userDetails }) {
      state.userDetails = userDetails;
    },
    deleteUser(state, userIndex) {
      state.users.splice(userIndex, 1);
    },
  },
  actions: {
    fetchUsersList({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.usersListURL,
            method: "GET",
          })
          .then((usersList) => {
            commit("updateUsersList", { usersList });
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
    fetchUserDetails({ commit }, { userID }) {
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.userDetailsURL.replace("{id}", userID),
            method: "GET",
          })
          .then((userDetails) => {
            commit("updateUserDetails", { userDetails });
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
    setUserDetails({ commit }, { payload, userID }) {
      let edit = userID != undefined;
      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: edit
              ? api.userDetailsURL.replace("{id}", userID)
              : api.usersListURL,
            data: payload,
            method: edit ? "PATCH" : "POST",
          })
          .then(() => {
            commit("userUpdated");
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
    deleteUser({ commit }, { index, miscData: { itemID } }) {
      let userID = itemID;
      let userIndex = index;

      return new Promise((resolve, reject) => {
        api
          .actionHandler({
            url: api.userDetailsURL.replace("{id}", userID),
            method: "DELETE",
          })
          .then(() => {
            commit("deleteUser", userIndex);
            resolve();
          })
          .catch((errMsg) => reject(errMsg));
      });
    },
  },
};
