<template>
  <nav
    class="navbar navbar-vertical bg-gradient-test fixed-left navbar-expand-md navbar-light"
    id="sidenav-main"
  >
    <div class="container-fluid">
      <!--Toggler-->
      <navbar-toggle-button @click.native="showSidebar">
        <span class="navbar-toggler-icon"></span>
      </navbar-toggle-button>
      <router-link class="navbar-brand" to="/">
        <img :src="logo" class="navbar-brand-img" alt="Company Logo" />
      </router-link>

      <hr class="my-0 w-100" />

      <slot name="mobile-right">
        <ul class="nav align-items-center d-md-none ml-auto">
          <!-- <notification-panel></notification-panel> -->
          <base-dropdown class="nav-item" position="right">
            <a slot="title" class="nav-link" role="button">
              <div class="media align-items-center">
                <span class="avatar avatar-sm rounded-circle">
                  <img
                    alt="Image placeholder"
                    :src="$store.state.accountData.profilePicture"
                  />
                  <!-- <img alt="Image placeholder" src="img/icons/user.png" > -->
                  <!-- "$store.state.loginData.profilePicture" -->
                </span>
              </div>
            </a>

            <mini-nav></mini-nav>
          </base-dropdown>
        </ul>
      </slot>
      <slot> </slot>
      <div
        v-show="$sidebar.showSidebar"
        class="navbar-collapse collapse show"
        id="sidenav-collapse-main"
      >
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img :src="logo" />
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <navbar-toggle-button
                @click.native="closeSidebar"
              ></navbar-toggle-button>
            </div>
          </div>
        </div>

        <ul class="navbar-nav rounded-right">
          <!-- This is where the main sidebar stuff gets in, See DashboardLayout.vue -->
          <slot name="links"> </slot>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import NavbarToggleButton from "@/components/NavbarToggleButton";
import MiniNav from "@/layout/MiniNav";

export default {
  name: "sidebar",
  components: {
    NavbarToggleButton,
    MiniNav,
  },
  props: {
    logo: {
      type: String,
      default: "./img/icons/tevho1.png",
      description: "Sidebar app logo",
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        "Whether sidebar should autoclose on mobile when clicking an item",
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    closeSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    showSidebar() {
      this.$sidebar.displaySidebar(true);
    },
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
